export const environment = {
  production: true,
  baseURL: 'https://api.medibird.com/index.php/',
  mediaURL: 'https://api.medibird.com/uploads/',
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  general: {
    symbol: 'Rs.',
    code: 'Rupee'
  },
  authToken: 'RviWsecCBN_ofAtscnst32YhaubRtHNM', 
  default_country_code: '91'
};
  